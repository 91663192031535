









































import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "Codes",

  data: () => ({
    isEditing: false
  }),

  computed: {
    ...mapGetters({
      codes: "codes/getCodes",
    })
  },

  mounted() {
    this.$store.dispatch("codes/fetch");
  },

  methods: {
    updateCode(event: string): void {
      this.$store.commit('codes/setCodes', {
        ...this.codes,
        codes: event
      })
    },

    saveCodes(): void {
      this.$store.dispatch('codes/update', this.codes)
      this.isEditing = false
    },
  }
})
